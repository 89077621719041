<template>
  <div>
			<v-toolbar style="outline:gray" flat>
				<v-spacer />
				<v-flex md1>
					<v-layout justify-start>
						<v-btn
							:disabled="parseInt(page)==1"
							@click="changePrevData"
							class="ma-2"
							text
							icon
						>
							<v-icon :disabled="parseInt(page)==1">mdi-chevron-left</v-icon>
						</v-btn>
					</v-layout>
				</v-flex>
				<v-flex md2 style="padding-top:15px;text-align: center">
					<p class="font-weight-light text--primary">
						Page
						{{ page }}
						out of
						{{ getTotalPage(header.document_number) }}
					</p>
				</v-flex>
				<v-flex md1 align-self-end>
					<v-layout justify-end>
						<v-btn
							@click="changeNextData"
							class="ma-2"
							text
							icon
						>
							<v-icon >mdi-chevron-right</v-icon>
						</v-btn>
					</v-layout>
				</v-flex>
			</v-toolbar>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th>#</th>
            <th>Analysis</th>
            <th>Department</th>
            <th>Branch</th>
            <th>Section</th>
            <th width="25%">Chart of Accounts</th>
            <th>Debit</th>
            <th>Credit</th>
            <th>Remarks</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(detail, detail_key) in filterTableData(header.document_number, page)"
            :key="detail.detail_key"
          >
            <td>{{ detail_key + 1 + ((rowPage * parseInt(page)) - rowPage) }}</td>
            <td>
              <v-autocomplete
                class="remove-underline"
                v-model="detail.payee_id"
                :items="payees"
                editable
                hide-details
				dense
                item-text="payee_desc"
                item-value="id"
                @change="setDetails(detail.payee_id, detail_key + ((rowPage * parseInt(page)) - rowPage), header.document_number)"
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="remove-underline"
                v-model="detail.department_id"
                :items="dataDepts"
                editable
                hide-details
				dense
                item-text="dept_desc"
                item-value="id"
                @change="getDeptData($event, detail_key + ((rowPage * parseInt(page)) - rowPage), header.document_number)"
              >
              </v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="remove-underline"
                v-model="detail.branch_id"
                :items="detail.branches"
				dense
                v-on:focus="getBranchData(detail.department_id, detail_key + ((rowPage * parseInt(page)) - rowPage), header.document_number)"
                editable
                hide-details
                item-text="code_name"
                item-value="id"
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="remove-underline"
                v-model="detail.section_id"
                :items="detail.sections"
				dense
                v-on:focus="getSectionData(detail.department_id, detail_key + ((rowPage * parseInt(page)) - rowPage), header.document_number)"
                editable
                hide-details
                item-text="section_name"
                item-value="section_id"
              ></v-autocomplete>
            </td>
            <td>
              <v-autocomplete
                class="remove-underline"
                v-model="detail.account_code"
                :items="coaItem"
				dense
                editable
                hide-details
                item-text="code_name"
                item-value="acct_code"
				@change="setCurrencyDetails(detail.account_code, detail_key + ((rowPage * parseInt(page)) - rowPage), header.document_number)"
              ></v-autocomplete>
            </td>
            <td>
              <v-text-field
                v-model="detail.debit_amount"
                :readonly="parseFloat((typeof detail.credit_amount ==='string' ? detail.credit_amount.replace(/,/g, '') : detail.credit_amount)) > 0 || detail.remarks === 'Tax Based Amount' || detail.remarks === 'Outright Amount'"
                type="text"
                class="input_number text-right"
                editable
				dense
                hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="detail.credit_amount"
                :readonly="parseFloat((typeof detail.debit_amount ==='string' ? detail.debit_amount.replace(/,/g, '') : detail.debit_amount)) > 0"
                type="text"
                class="input_number text-right"
                editable
				dense
                hide-details
              ></v-text-field>
            </td>
            <td>
              <v-text-field
                v-model="detail.remarks"
                 type="text"
                editable
				dense
                hide-details
              ></v-text-field>
            </td>
            <td>
              <v-btn icon @click="removeDetail(header.document_number, detail_key + ((rowPage * parseInt(page)) - rowPage))">
                <v-icon small color="red">delete</v-icon>
              </v-btn>
            </td>
          </tr>
          <tr>
            <td colspan="4">
              <b>Total</b>
            </td>
            <td>&nbsp;</td>
            <td class="text-right">
            </td>
            <td class="text-right">
              <b>{{header.overallDebit}}</b>
            </td>
            <td class="text-right">
              <b>{{header.overallCredit}}</b>
            </td>
            <td>&nbsp;</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-card-actions>
      <v-btn right dark color="primary" @click="addDetail(header.document_number)">
        <v-icon>mdi-plus</v-icon>
        <span>Add new Row</span>
      </v-btn>
      <!-- <input id="xlsx" :data-doc-number="header.document_number" type="file" @change="importExcel"> -->
      <upload-btn
        @file-update="importExcel"
        :document_id="header.document_number"
        title="Import Spreadsheet">
      </upload-btn>
    </v-card-actions>
  </div>
</template>
<script>
import UploadButton from '@/components/partials/AppImportButton';
import numeral from 'numeral';
// import xlsx from 'xlsx';
import jsonToFormData from 'json-form-data';
import { mapGetters } from 'vuex';
import { format } from 'date-fns';

export default {
	components: {
		'upload-btn': UploadButton,
	},
	props: {
		document_type: {
			type: String,
			required: true
		},
		headerValue: {
			type: Array
		},
		detailValue: {
			type: Array
		},
		method_type: {
			type: String
		},
		batch_document_date: {
			type: String
		},
		batch_number: {
			type: String
		},
		is_pdc: {
			type: Boolean
		},
		header: {
			type: Object
		},
		currency_id: {
			type: Number
		},
		dataBanks: {
			type: Array
		},
		dataDepts: {
			type: Array
		},
		dataSections: {
			type: Array
		},
	},
	data() {
		return {
			headers: [],
			details: [],
			page: 1,
			pages: [],
			totalPage: 1,
			dataCoas: [],
			nextPageCoas: '',
			rowPage: process.env.VUE_APP_TRX_DETAIL_ROW,
			documentNumber : '',
		};
	},
	computed: {
		...mapGetters({
			coaData: 'coa/coaData',
			branches: 'branch/branches',
			payeefile: 'payefile/payefiles',
			bpbanks: 'bpbank/bpbanks',
			payeebanks: 'payeebank/payeebanks',
			alphataxcodes: 'alphataxcode/alphataxcodes',
			regTrxDetails: 'regularTxns/regulartxn',
			dist_data: 'trxdisb/dist_data',
			currUser: 'auth/currUser',
			newDocNum: 'trxdisb/newDocNum',
			coaItem: 'coa/coaCurr',
			uploadEntries: 'trxdisb/uploadEntries',
		}),
		payees(){
			let data = this.payeefile.filter(e => e.status === 1);
			return data;
		}
	},
	watch: {
		document_type: {
			handler(val) {
				this.changeData(val);
			}
		},
		currency_id: {
			handler(val){
				this.$emit('update:currency_id', val);
				this.getCoaCurr(val);
			}
		},
		headerValue: {
			handler(val) {
				this.$emit('update:headerValue', val);
			},
			deep: true
		},
		method_type: {
			handler(val) {
				this.$emit('update:method_type', val);
			},
			deep: true
		},
		detailValue: {
			handler(val) {
				this.setNewDetail(val);
				this.$emit('update:detailValue', val);
			},
			deep: true
		},
		details: {
			handler(val) {
				this.setNewDetail(val);
				this.$emit('update:detailValue', val);
			},
			deep: true
		},
		batch_document_date: {
			handler(val) {
				this.$emit('update:batch_document_date', val);
			},
			deep: true
		},
		batch_number: {
			handler(val) {
				this.$emit('update:batch_number', val);
			},
			deep: true
		},
		uploadEntries: {
			handler(val){
				this.setNewEntries(val);
			}
		}
	},
	mounted() {
		this.$store.dispatch('regularTxns/getregularTxns');
		this.getPayees();
		this.getBranches();

		this.headers = this.headerValue;
		this.setNewDetail(this.detailValue);
		this.getCoaCurr(this.currency_id);
	},
	methods: {
		getBranches() {
			this.$store.dispatch('branch/getBranchDropdown');
		},
		getPayees() {
			this.$store.dispatch('payefile/getPayefileDropdown');
		},
		getPayeeBanks() {
			this.$store.dispatch('payeebank/getPayeeBankDropdown');
		},
		getCoaCurr(curr_id){
			let data = this.coaData.filter(detail => detail.currency_id == curr_id);
			this.$store.commit('coa/SET_COA_CURR', data, { root: true });
		},
		addDetail(key) {
			let distData = this.dist_data;
			let data = [];
			let distDetail = [];
			let payee_id = 0;
			let department_id = 0;
			let section_id = 0;
			let regionalhead_id = 1;
			let branch_id = 0;
			let acct_code = '';

			data = distData.journal_entry;

			this.dataBanks.filter(bankDetails => {
				if(bankDetails.id == this.bank_account_id){
					acct_code = parseInt(bankDetails.acct_code);
				}
			});

			if(data){
				distDetail = data.details.filter(detail => { return detail.document_number == key;});
				payee_id = distDetail[0].payee_id;
				department_id = distDetail[0].department_id;
				section_id = distDetail[0].section_id;
				regionalhead_id = distDetail[0].regionalhead_id;
				branch_id = distDetail[0].branch_id;
			}
			
			let newRow = {
				payee_id: payee_id,
				department_id: department_id,
				section_id: section_id,
				regionalhead_id: regionalhead_id,
				branch_id: branch_id,
				document_number: key,
				document_date: format(new Date(), 'yyyy-MM-dd'),
				account_code: acct_code,
				remarks: 'New Entry',
				debit_amount: numeral(0).format('0,0.[00]'),
				credit_amount: numeral(0).format('0,0.[00]'),
			};

			this.details.push(newRow);

			let countDocumentDetail = this.filterDetailsByDocumentNumber(key);

			this.getDeptData(department_id, countDocumentDetail.length - 1, key);
		},
		getDeptData(dept_id, index, document_number) {
			var filterDetails = this.filterDetailsByDocumentNumber(document_number);
			var remainingDetails = this.details.filter(detail => detail.document_number !== document_number);
			
			if (filterDetails) {
				this.dataDepts.filter(depts_details => {
					if(depts_details.id === dept_id){			
						if (depts_details.sections.length > 0) {
							filterDetails[index].sections = depts_details.sections;
							if(filterDetails[index].section_id){
								filterDetails[index].section_id = depts_details.sections.includes(filterDetails[index].section_id) ? filterDetails[index].section_id : '' ;
							}
						} else {
							filterDetails[index].sections = [];
							filterDetails[index].section_id = 0;
						}
					}

					if(dept_id == 1 || dept_id == 25 || dept_id == 26 || dept_id == 29){
						filterDetails[index].branches = this.branches.filter(e => e.brnc_stat === 'OPEN');
					} else {
						filterDetails[index].branches = this.branches.filter(det => det.brnc_code === 'HOB' && det.brnc_stat === 'OPEN');
					}

					if(dept_id !== 6 && dept_id !== 25 && dept_id !== 26 && dept_id !== 29 && dept_id !== 30){
						filterDetails[index].branch_id = 114;
					} else {
						filterDetails[index].branch_id = '';
					}
				});

				var concatDetails = filterDetails.concat(remainingDetails.filter((item) => filterDetails.indexOf(item) < 0));
				this.details = concatDetails;
			}
		},
		getBranchData(dept_id, index, document_number) {
			var filterDetails = this.filterDetailsByDocumentNumber(document_number);
			var remainingDetails = this.details.filter(detail => detail.document_number !== document_number);
			
			if (filterDetails) {
				if(dept_id == 1 || dept_id == 25 || dept_id == 26 || dept_id == 29){
					filterDetails[index].branches = this.branches.filter(e => e.brnc_stat === 'OPEN');
				} else {
					filterDetails[index].branches = this.branches.filter(det => det.brnc_code === 'HOB' && det.brnc_stat === 'OPEN');
				}

				if(dept_id !== 6 && dept_id !== 25 && dept_id !== 26 && dept_id !== 29 && dept_id !== 30){
					filterDetails[index].branch_id = 114;
				} else {
					filterDetails[index].branch_id = '';
				}
			}

			var concatDetails = filterDetails.concat(remainingDetails.filter((item) => filterDetails.indexOf(item) < 0));
			this.details = concatDetails;
		},
		getSectionData(dept_id, index, document_number) {
			var filterDetails = this.filterDetailsByDocumentNumber(document_number);
			var remainingDetails = this.details.filter(detail => detail.document_number !== document_number);
			
			if (filterDetails) {
				this.dataDepts.filter(depts_details => {
					if(depts_details.id === dept_id){			
						if (depts_details.sections.length > 0) {
							filterDetails[index].sections = depts_details.sections;
							if(filterDetails[index].section_id){
								filterDetails[index].section_id = depts_details.sections.includes(filterDetails[index].section_id) ? filterDetails[index].section_id : '' ;
							}
						} else {
							filterDetails[index].sections = [];
							filterDetails[index].section_id = 0;
						}
					}
				});

				var concatDetails = filterDetails.concat(remainingDetails.filter((item) => filterDetails.indexOf(item) < 0));
				this.details = concatDetails;
			}
		},
		setCurrencyDetails(account, index, document_number) {
			var filterDetails = this.filterDetailsByDocumentNumber(document_number);
			var remainingDetails = this.details.filter(detail => detail.document_number !== document_number);
			
			if (filterDetails) {
				this.coaItem.filter(det => {
					if(det.acct_code === account){
						filterDetails[index].currency_id = det.currency_id;
					}
				});

				var concatDetails = filterDetails.concat(remainingDetails.filter((item) => filterDetails.indexOf(item) < 0));
				this.details = concatDetails;
			}
		},
		filterDetailsByDocumentNumber(id) {
			return this.details.filter(detail => detail.document_number === id);
		},
		filterTableData(id, page){
			let newData = this.filterDetailsByDocumentNumber(id);
			var perChunk = this.rowPage;
			var result = newData.reduce((resultArray, item, index) => { 
				const chunkIndex = Math.floor(index/perChunk);

				if(!resultArray[chunkIndex]) {
					resultArray[chunkIndex] = [];
				}

				resultArray[chunkIndex].push(item);
				return resultArray;
			}, []);
			return result[page-1];
		},
		getTotalPage(doc_id){
			const docNumber = parseInt(doc_id);
			const filterDetails = this.filterDetailsByDocumentNumber(docNumber);
			let count = filterDetails.length / this.rowPage;
			let dec = count.toString().split('.');
			let total = 1;
			if(dec[1]){
				total = parseInt(dec[0]) + 1;
			}else{
				total = count;
			}

			return parseInt(total);
		},
		async importExcel(e, doc_id) {
			this.documentNumber = doc_id;
			const files = e.target.files, f = files[0];
			let payload = {
				upload_file : f,
				edit_by: this.currUser.id
			};
			var options = {
				initialFormData: new FormData(),
				showLeafArrayIndexes: true,
				includeNullValues: false,
				mapping: function(value) {
					if (typeof value === 'boolean') {
						return +value ? '1': '0';
					}
					return value;
				}
			};

			var convertedFormData = jsonToFormData(payload, options);
			await this.$store.dispatch('trxdisb/uploadEntries', convertedFormData);
		},
		setNewEntries(detail){
			let docNumber =  this.documentNumber;
			let sections = this.dataSections;
			let branches = this.branches;
			const app = this.$data;
			if(this.documentNumber){
				const filterDetails = this.filterDetailsByDocumentNumber(docNumber);
			
				app.headers.forEach(header => {
					header.enable_tax_base = '0';
					header.check_type = '';
				});

				//Overwrites the current table
				if (filterDetails.length > 1) {
					const specificDetail = app.details.filter(e => e.document_number !== docNumber);

					app.details = [];
					app.details = specificDetail;
				
					//Re-initialize push
					app.details.push({
						section_id: 1,
						regionalhead_id: 1,
						document_number: docNumber,
						document_date: format(new Date(), 'yyyy-MM-dd'),
						remarks: 'New Entry',
						account_code: '',
					});
				}
				app.details.map(function(detailData) {
					detail.forEach((excelValue, index) => {
						let doc_date = '';
						doc_date = format(new Date(), 'yyyy-MM-dd');
						if (index === 0) {
							detailData.payee_id = excelValue.payee_id;
							detailData.department_id = excelValue.department_id;
							detailData.section_id = (!excelValue.section_id ? 0 : excelValue.section_id);
							detailData.regionalhead_id = (!excelValue.section_id ? 0 : excelValue.section_id);
							detailData.branch_id = excelValue.branch_id;
							detailData.account_code = excelValue.account_code;
							detailData.currency_id = excelValue.currency_id;
							detailData.document_date = doc_date;
							detailData.debit_amount = parseFloat(excelValue.debit_amount);
							detailData.credit_amount = (parseFloat(excelValue.debit_amount) > 0) ? 0 : parseFloat(excelValue.credit_amount);
							detailData.document_number = docNumber;
							detailData.reference_number = '';
							detailData.remarks = excelValue.remarks;
							detailData.sections = sections;
							detailData.branches = branches;
						} else {
							app.details.push({
								payee_id: excelValue.payee_id,
								department_id: excelValue.department_id,
								section_id: (!excelValue.section_id ? 0 : excelValue.section_id),
								regionalhead_id: (!excelValue.section_id ? 0 : excelValue.section_id),
								branch_id: excelValue.branch_id,
								account_code: excelValue.account_code,
								currency_id : excelValue.currency_id,
								document_number: docNumber,
								document_date : excelValue.document_date,
								debit_amount: parseFloat(excelValue.debit_amount),
								credit_amount: (parseFloat(excelValue.debit_amount) > 0) ? 0 : parseFloat(excelValue.credit_amount),
								reference_number: '',
								remarks: excelValue.remarks,
								sections : sections,
								branches : branches
							});	
						}
					});
				});
			}

		},
		setNewDetail(val){
			this.details = val;
		},
		setDefaultAccount(id){
			let account_code = '';
			this.dataBanks.filter(bankDetails => {
				if(bankDetails.id == id){
					account_code = bankDetails.acct_code;
				}
			});
			let account_id = '';
			if(account_code){
				this.coaData.filter(account => {
					if(account.acct_code == account_code){
						account_id = account.id;
					}
				});
			}

			if(account_id){
				const data = this.$data;

				data.headers.forEach(headerData => { 
					const filterDetails = this.filterDetailsByDocumentNumber(
						headerData.document_number
					);
					filterDetails.forEach((detailData) => {
						detailData.account_code = parseInt(account_code);
					});
				});
			}
		},
		removeDetail(document_number, header_key) {
			const countDetails = this.filterDetailsByDocumentNumber(document_number);
			if (countDetails.length > 1) {
				this.details.filter((detail, index) => {
					if (detail.document_number === document_number) {

						if (index === header_key) {
							this.details.splice(header_key, 1);
						}
					}
				});
			}
		},
		changeData(id){
			let distData = this.dist_data;
			let data = '';
			
			if (id == 'JE'){
				if('journal_entry' in distData){
					data = distData.journal_entry;
				}
			}

			if(data) {
				this.headers = data.headers;
				this.details = data.details;
			}
		},
		setDetails(id, key, header_document){
			this.getBranches();
			this.getDepts();

			const filterDetails = this.filterDetailsByDocumentNumber(header_document);
			this.payees.filter((payee_details) => {
				if(payee_details.id == id) {
					filterDetails[key].branch_id = '';
					filterDetails[key].section_id = 0;
					filterDetails[key].department_id = '';

					this.branches.filter(branches_details => {
						if(branches_details.brnc_code == payee_details.brnc_code){
							filterDetails[key].branch_id = branches_details.id;
						}
					});
					
					this.sections.filter(section_details => {
						if(section_details.section_code == payee_details.section_code){
							filterDetails[key].section_id = section_details.id;
						}
					});

					this.dataDepts.filter(depts_details => {
						if(depts_details.dept_code == payee_details.dept_code){
							filterDetails[key].department_id = depts_details.id;
						}
					});

					this.getDeptData(filterDetails[key].department_id, key, header_document);
				}
			});
		},
		changeNextData(){
			let pages = parseInt(this.page);
			this.page = pages + 1;
		},
		changePrevData() {
			let pages = parseInt(this.page);
			this.page = pages - 1;
		},
	}
};
</script>
<style scoped>
  .v-autocomplete {
    font-size: 14px
  }
  .amount {
    font-size: 14px
  }
</style>
