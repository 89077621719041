<template>
  <v-row justify="center">
		<v-expansion-panels focusable multiple tile outlined>
			<v-expansion-panel
				v-for="(header, key) in headers" :key="header.key"
				class="document-card-container" outlined expand
				value="0"
			>
				<v-expansion-panel-header tile outlined>
					<template>
						<v-card-title dark class="document-card-title">
							Document No. {{header.document_number}}
							<v-spacer></v-spacer>
							<v-btn icon @click.stop="removeheader(key, header.document_number)" v-if="headers.length > 1">
								<v-icon>mdi-close</v-icon>
							</v-btn>
						</v-card-title>
					</template>
				</v-expansion-panel-header>
				<v-expansion-panel-content outlined>
					<template>
						<v-card tile outlined class="document-form-container">
							<v-container grid-list-md>
								<v-layout wrap>
									<v-flex xs12 sm12 md12>
										<p>Select Transaction Type:</p>
										<v-radio-group v-model="header.disbursement_type" row>
											<v-radio label="Regular" value="1"></v-radio>
											<v-radio label="Non-Regular" value="2"></v-radio>
										</v-radio-group>
									</v-flex>
									<v-flex xs12 sm12 md12 v-if="header.disbursement_type == '1'">
										<p>Regular Transaction:</p>
										<v-radio-group v-model="header.regular_trx_type" row>
											<v-radio label="Select from the Existing List" value="1"></v-radio>
											<v-radio label="Create a new one" value="2"></v-radio>
										</v-radio-group>
									</v-flex>
									
									<v-flex xs12 sm6 md12 v-if="header.disbursement_type == '1' && header.regular_trx_type == '1' && regular_trx.length > 0">
										<p>Select an Existing Regular Transaction:</p>
										<!-- <v-radio-group row v-for="(trxdetail, key) in regular_trx" :key="trxdetail.key" v-model="header.regular_trx_selected" @change="populateRegTrxData(header.document_number, trxdetail)">
											<v-radio :label='trxdetail.txn_type' :value="trxdetail.id"></v-radio>
										</v-radio-group> -->
										<v-autocomplete
											placeholder="Please select an existing transaction"
											:items="regular_trx"
											item-text="txn_type"
											item-value="id"
											v-model="header.regular_trx_selected"
											@change="
												populateRegTrxData(header.document_number, header.regular_trx_selected)
											"
										>
										</v-autocomplete>
									</v-flex>
									<v-flex xs12 sm6 md12 v-if="header.disbursement_type == '1' && header.regular_trx_type == '1' && regular_trx.length < 1">
										<p>No available regular transactions available. Please create a new one</p>
									</v-flex>
									<v-flex xs12 sm12 md12 v-if="header.disbursement_type == '1'">
										<v-text-field label="Title" v-model="headers[key].txn_type" required></v-text-field>
									</v-flex>
									<v-flex xs12 sm6 md4>
										<v-text-field
											label="Document Number"
											v-model="headers[key].document_number"
											required
											readonly
										></v-text-field>
									</v-flex>
									<!-- <v-flex xs12 sm6 md4>
										<v-text-field label="Amount" required></v-text-field>
									</v-flex>-->
									<v-flex xs12 sm6 md4>
										<v-autocomplete
											v-model="headers[key].payee_id"
											:items="payees"
											editable
											item-text="payee_desc"
											item-value="id"
											label="Payee"
											:readonly="Object.keys(dataPO).length !== 0"
											v-on:change="payees_on_change(headers[key].payee_id, headers[key])"
											return-object
										></v-autocomplete>
									</v-flex>
									<v-flex xs12 sm6 md4>
										<v-autocomplete
											v-model="headers[key].payee_account_id"
											:items="headers[key].payee_bank"
											editable
											item-text="item_data"
											item-value="payee_account_id"
											label="Payee Bank"
										></v-autocomplete>
									</v-flex>
									<v-flex xs12 sm6 md4>
										<v-autocomplete
											v-model="headers[key].atc_code"
											:items="dataAtc"
											editable
											item-text="atc_desc"
											item-value="atc_code"
											label="ATC Code"
											@change="atc_code_on_change($event, key)"
										>
										<template slot="item" slot-scope="data">
											{{ data.item.atc_code }} - {{ data.item.atc_desc }} 
										</template> 
										</v-autocomplete>
									</v-flex>
									<v-flex xs12 sm6 md2>
										<v-text-field label="ATC Rate" v-model="headers[key].atc_rate" readonly required></v-text-field>
									</v-flex>
									<v-flex xs12 sm6 md2>
										<v-checkbox 
											label="Tax Base"
											false-value="0"
											true-value="1"
											v-model="headers[key].enable_tax_base"
											@change="checkTaxBase($event, headers[key].document_number)"
										></v-checkbox>
									</v-flex>
									<v-flex xs12 sm6 md4>
										<v-text-field label="Tax Base Amount" 
											v-model="headers[key].tax_base_amount"
											:readonly="headers[key].enable_tax_base === '0'"
										></v-text-field>
									</v-flex>
									<v-flex xs12 sm6 md12>
										<h3>Computed Tax:</h3>
										<p> {{headers[key].tax_computed ? headers[key].tax_computed : 0}}</p>
									</v-flex>
									<v-flex xs12 sm6 md12>
										<v-textarea
											v-model="headers[key].remarks"
											editable
											label="Remarks"
											rows="2"
											counter=2000
										></v-textarea>
									</v-flex>
									<!-- <v-flex xs12 sm12 md12 v-if="document_type == 'OB'">
										<v-checkbox 
											v-model="headers[key].auto_debit" 
											label="Is this an auto-debit transaction?"
											false-value="0"
											true-value="1"
										></v-checkbox>
									</v-flex> -->
								</v-layout>
							</v-container>
							<trx-detail ref="createTrxDetail" 
								:header.sync="headers[key]" 
								:is_pdc.sync="is_pdc" 
								:batch_number.sync="batch_number" 
								:batch_document_date.sync="date" 
								:method_type.sync="method_type" 
								:headerValue.sync="headers" 
								:detailValue.sync="details" 
								:document_type.sync="document_type" 
								:currency_id.sync="currency_id" 
								:dollar_rate.sync="dollar_rate"
								:dataBanks.sync="dataBanks"
								:dataDepts.sync="dataDepts"
								:dataSections.sync="dataSections"
								:dataPO.sync="dataPO">
							</trx-detail>
						</v-card>
					</template>
				</v-expansion-panel-content>
			</v-expansion-panel>
			<v-card-actions>
				<v-btn right @click="addHeader" color="primary" dark :disabled="addheader" v-if="Object.keys(dataPO).length === 0 ">
					<v-icon>mdi-plus</v-icon>
					<span>Add New Document</span>
				</v-btn>
			</v-card-actions>
		</v-expansion-panels>
  </v-row>
</template>
<script>
import trxDetail from './createJournalDetail';
import { mapGetters } from 'vuex';
import { mask } from 'vue-the-mask';
import numeral from 'numeral';
import { format, startOfMonth, addYears, parseISO } from 'date-fns';


export default {
	props: {
		dataRegularTrx: {
			type:Array
		},
		document_type: {
			type: String,
			required: true
		},
		newDetail: {
			type: Object
		},
		method_type: {
			type: String
		},
		batch_document_date: {
			type: String
		},
		batch_number: {
			type: String
		},
		currency_id: {
			type: Number
		},
		dollar_rate: {
			type: String
		},
		dataBanks: {
			type: Array
		},
		dataDepts: {
			type: Array
		},
		dataPO: {
			type: Object
		},
	},
	directives: {
		mask,
	},
	components: {
		'trx-detail': trxDetail,
	},
	data() {
		return {
			date_format: '####-##-##',
			date: '',
			cheque_datepicker: [],
			voucher_datepicker: [],
			due_datepicker: [],
			minDate: '',
			maxDate: '',
			addheader: false,
			overallDebit: 0,
			overallCredit: 0,
			disbursement_type: '1',
			enable_tax_base: '0',
			regular_trx_selected: '',
			show: false,
			is_pdc: false,
			headers: [],
			details: [],
			populate_entries: [],
			regular_trx: [],
			isMultipleATC: false,
			dataAtc: [],
			nextPageAtc: '',
			dataSections: [],
			nextPageSections: '',
			date_rules: [
				v => !!v || 'Date is required',
			],
			validations: {
				name: {
					is_valid: true,
					text: ''
				},
				headers: [],
				oneheader: {
					is_valid: true,
					text: ''
				},
				website: {
					is_valid: true,
					text: ''
				}
			}
		}; 
	},
	watch: {
		newDetail: {
			handler(val) {
				this.setNewHeader(val);
			}
		},
		document_type: {
			handler(val) {
				this.changeData(val);
			}
		},
		currency_id: {
			handler(val){
				this.$emit('update:currency_id', val);
			}
		},
		dollar_rate: {
			handler(val){
				this.$emit('update:dollar_rate', val);
			}
		},
		headers: {
			handler(val) {
				this.$emit('update:headerValue', val);
			},
			deep: true
		},
		method_type: {
			handler(val) {
				this.$emit('update:method_type', val);
			},
			deep: true
		},
		details: {
			handler(val) {
				this.$emit('update:detailValue', val);
			},
			deep: true
		},
		batch_document_date: {
			handler(val) {
				this.$emit('update:batch_document_date', val);
			},
			deep: true
		},
		batch_number: {
			handler(val) {
				this.$emit('update:batch_number', val);
			},
			deep: true
		},
		dataRegularTrx: {
			handler(val) {

				let filteredResult = val.filter(value => {
					return value.is_approved !== 0 && value.document_type === 'JE' && parseInt(value.edit_by) === parseInt(this.currUser.id);
				});

				this.regular_trx = filteredResult;
			},
			deep: true
		},
		alphataxcodes: {
			handler() {
				this.getDataAlphataxcodes();
			}
		},
		sections: {
			handler() {
				this.getDataSections();
			}
		},
		coas: {
			handler(){
				this.getDataCoas();
			}
		},
		$data: {
			handler(val) {
				//loops the header array
				val.headers.forEach(headerData => {
					let payee_id = '';
					let department_id = '';
					let section_id = 0;
					let branch_id = '';

					headerData.reference_number = headerData.document_number;

					let startDate = '';
					let endDate = '';

					let batchDocDate = format(parseISO(this.batch_document_date), 'yyyy-MM-dd');
					
					let formatStartDate = '';
					let formatEndDate = '';
					
					startDate = startOfMonth(parseISO(batchDocDate));
					endDate = addYears(parseISO(batchDocDate), 1);

					formatStartDate = format(startDate, 'yyyy-MM-dd');
					formatEndDate = format(endDate, 'yyyy-MM-dd');

					headerData.minDate = formatStartDate;
					headerData.maxDate = formatEndDate;

					let computation = headerData.tax_base_amount * parseFloat((headerData.atc_rate/100));

					headerData.tax_computed = numeral(computation).format('0,0.00');					
					headerData.voucher_number = headerData.document_number;

					//filters the details by document number
					let filterDetails = this.filterDetailsByDocumentNumber(
						headerData.document_number
					);

					if(filterDetails.length !== 0){
						payee_id = filterDetails[0].payee_id;
						branch_id = filterDetails[0].branch_id;
						department_id = filterDetails[0].department_id;
						section_id = filterDetails[0].section_id;
					}

					if(Object.keys(this.dataPO).length !== 0 ){
						payee_id = this.dataPO.payee_id;
					}

					filterDetails.forEach((detail) => {
						detail.debit_amount = numeral(detail.debit_amount).format('0,0.[00]');
						detail.credit_amount = numeral(detail.credit_amount).format('0,0.[00]');
					});

					let countTBAEntry = this.details.filter(function(detail) {
						return detail.remarks === 'Tax Based Amount' && detail.document_number === headerData.document_number;
					});

					//checks if tax base amount is checked
					if (headerData.enable_tax_base === '1') {
						if (parseFloat(computation) > 0) {
							let addTaxbaseDetail = '';
					
							filterDetails.forEach((detail) => {
								if (countTBAEntry.length > 1) {
									filterDetails.forEach((detail) => {
										if (detail.remarks === 'Tax Based Amount') {
											this.details.splice(0, 1);
										}
									});
								}

								if (countTBAEntry.length > 0) {									
									if (detail.remarks === 'Tax Based Amount') {
										detail.payee_id = payee_id;
										detail.department_id = department_id;
										detail.section_id = section_id;
										detail.regionalhead_id = 1;
										detail.branch_id = branch_id;
										detail.account_code = this.currency_id === 1 ? 2117001 : 2117002;
										detail.document_number = headerData.document_number;
										detail.document_date = format(new Date(), 'yyyy-MM-dd');
										detail.remarks = 'Tax Based Amount';
										detail.debit_amount = numeral(0).format('0,0.[00]');
										detail.credit_amount = numeral(computation).format('0,0.[00]');

										this.dataDepts.forEach((dept_detail) => {
											if (dept_detail.id === department_id) {
												detail.sections = dept_detail.sections;
											}
										});

									}
								} else {
									addTaxbaseDetail = {
										payee_id: payee_id,
										department_id: department_id,
										section_id: section_id,
										regionalhead_id: 1,
										branch_id: branch_id,
										account_code : this.currency_id === 1 ? 2117001 : 2117002,
										document_number: headerData.document_number,
										document_date: format(new Date(), 'yyyy-MM-dd'),
										remarks: 'Tax Based Amount',
										debit_amount: numeral(0).format('0,0.[00]'),
										credit_amount: numeral(computation).format('0,0.[00]')
									};

								}
							});

							if (this.method_type !== 'Save' && this.method_type !== 'Save as Draft') {
								if (countTBAEntry.length < 1) {
									this.details.unshift(addTaxbaseDetail);
								}			
							}											
						} else {
							filterDetails.forEach((detail) => {
								if (detail.remarks === 'Tax Based Amount') {
									this.details.splice(0, 1);
									this.method_type = '';
								}
							});
						}
					} else {
						filterDetails.forEach((detail) => {
							if (detail.remarks === 'Tax Based Amount') {
								this.details.splice(0, 1);
								this.method_type = '';
							}
						});
					}

					//computes the total debit in a specific document
					const totalDebit = filterDetails.reduce((sum, details) => {
						if (details.debit_amount === '') {
							details.debit_amount = 0;
						}

						return sum + parseFloat(details.debit_amount.replace(/,/g, ''));
					}, 0);

					//computes the total credit in a specific document
					const totalCredit = filterDetails.reduce((sum, details) => {
						if (details.credit_amount === '') {
							details.credit_amount = 0;
						}
						
						return sum + parseFloat(details.credit_amount.replace(/,/g, ''));
					}, 0);


					if (headerData.enable_tax_base === '1') {
						if (parseFloat(computation) > 0) {
							if (headerData.tax_base_amount == '' || headerData.cheque_amount == '') {
								headerData.voucher_amount = 0;
							} else {
								headerData.voucher_amount = (parseFloat(totalCredit)).toFixed(2);
							}
						}
					}

					//displays the total below the table
					headerData.overallDebit = numeral(totalDebit).format('0,0.00');
					headerData.overallCredit = numeral(totalCredit).format('0,0.00');
					
					if(totalDebit < totalCredit){
						headerData.amount = totalCredit;
					} else {
						headerData.amount = totalDebit;
					}
				});

				this.is_pdc = false;

				this.setStorage(val);
			},
			deep: true
		}
	},
	computed: {
		...mapGetters({
			branches: 'branch/branches',
			sections: 'section/sections',
			payeefile: 'payefile/payefiles',
			bpbanks: 'bpbank/bpbanks',
			payeebanks: 'payeebank/payeebanks',
			alphataxcodes: 'alphataxcode/alphataxcodes',
			regulartrx: 'regularTxns/regulartxns',
			regTrxDetails: 'regularTxns/regulartxn',
			dist_data: 'trxdisb/dist_data',
			currUser: 'auth/currUser',
			newDocNum: 'trxdisb/newDocNum',
			currencies: 'currency/currencies',
			coas: 'coa/coaSubs',
			coaData: 'coa/coaData'
		}),
		payees(){
			let data = this.payeefile.filter(e => e.status === 1);
			return data;
		}
	},
	mounted() {
		// this.$store.dispatch('regularTxns/getregularTxns');
		this.$store.dispatch('currency/getCurrencies');
		this.$store.dispatch('coa/getCoaDropdown');
		this.addHeaderMounted();
		this.getPayees();
		this.getBranches();
		this.getSections();
		//this.getDepts();
		this.getAlphaTaxCodes();
	},
	methods: {
		chequeDatepickerSelect(date, index) {
			if (date) {
				this.cheque_datepicker[index] = false;
			}
		},
		voucherDatepickerSelect(date, index) {
			if (date) {
				this.voucher_datepicker[index] = false;
			}
		},
		dueDatepickerSelect(date, index) {
			if (date) {
				this.due_datepicker[index] = false;
			}
		},
		getSections() {
			this.$store.dispatch('section/getSectionDropdown');
		},
		getBranches() {
			this.$store.dispatch('branch/getBranchDropdown');
		},
		getDepts() {
			this.$store.dispatch('dept/getDeptDropdown');
		},
		getAlphaTaxCodes() {
			this.$store.dispatch('alphataxcode/getAlphataxcodeDropdown');
		},
		getPayees() {
			this.$store.dispatch('payefile/getPayefileDropdown');
		},
		getPayeeBanks() {
			this.$store.dispatch('payeebank/getPayeeBankDropdown');
		},
		getDeptData(dept_id, index, document_number) {
			var filterDetails = this.filterDetailsByDocumentNumber(document_number);
			var remainingDetails = this.details.filter(detail => detail.document_number !== document_number);
			
			if (filterDetails) {
				this.dataDepts.filter(depts_details => {
					if(depts_details.id === dept_id){						
						if (depts_details.has_section > 0) {
							filterDetails[index].sections = depts_details.sections;
							if(filterDetails[index].section_id){
								filterDetails[index].section_id = depts_details.sections.includes(filterDetails[index].section_id) ? filterDetails[index].section_id : '' ;
							}
						} else {
							filterDetails[index].sections = [];
							filterDetails[index].section_id = 0;
						}
					}
				});

				var concatDetails = filterDetails.concat(remainingDetails.filter((item) => filterDetails.indexOf(item) < 0));
				this.details = concatDetails;
			}
		},
		checkMonth(date) {
			let formatDate = new Date(date);
			let getMonth = formatDate.getMonth() + 1;

			if (getMonth !== 12) {
				return true;
			} else {
				return false;
			}
		},
		checkTaxBase(is_checked, document_number) {

			this.headers.forEach((header) => {
				if (header.document_number === document_number) {
					if (is_checked === '1') {
						header.enable_tax_base = '1';
					} else {
						header.enable_tax_base = '0';
					}
				}
			});
		},
		clearForm() {
			this.headers = [];
			this.details = [];
			this.validations = {
				name: {
					is_valid: true,
					text: ''
				},
				headers: [],
				oneheader: {
					is_valid: true,
					text: ''
				},
				website: {
					is_valid: true,
					text: ''
				}
			};
			this.addHeader();
		},
		async addHeader() {
			this.addheader = true;
			const distData = this.dist_data;
			
			let data = { 
				document_date: format(new Date(), 'yyyy-MM-dd'),
				document_type: this.document_type,
				edit_by: this.currUser.id
			};
			
			let headerDoc = [];
			
			this.headers.forEach((headerDocNum) => {
				headerDoc.push(headerDocNum.document_number);
			});
			
			let unique_id = '';
			let datas = [];

			distData.journal_entry.document_number.map(i => {
				let detailData = {document_number: parseInt(i), matched: headerDoc.includes(parseInt(i))};
				datas.push(detailData);
			});

			let num = datas.filter(det => {
				return det.matched === false; 
			});
			if(num.length !== 0){
				unique_id = num[0].document_number;
			}
			
			if (this.document_type == 'JE') {
				if(!unique_id){
					data['batch_number'] = distData.journal_entry.batch_number;
					await this.$store.dispatch('trxdisb/getDocumentNumber', data).then(response => {
						let data = distData.journal_entry;
						data['document_number'].push(response.data.data.document_number);
						unique_id = parseInt(response.data.data.document_number);
					});
				}

				this.addHeaderItem(unique_id);
			}

			this.details.push({
				section_id: 1,
				regionalhead_id: 1,
				document_number: unique_id,
				document_date: format(new Date(), 'yyyy-MM-dd'),
				account_code: '',
				debit_amount: 0,
				credit_amount: 0,
				remarks: 'New Entry'
			});

			this.validations.headers.push({
				document_number: {
					is_valid: true,
					text: ''
				},
				city: {
					is_valid: true,
					text: ''
				},
				state: {
					is_valid: true,
					text: ''
				},
				zip: {
					is_valid: true,
					text: ''
				}
			});

			this.addheader = false;
		},
		addHeaderItem(document_number){
			let payload = {
				company_id: 1,
				batch_number: '',
				regular_trx_type: '1',
				disbursement_type: '1',
				regular_trx_selected: '',
				document_number: document_number,
				atc_code: '',
				currency_id: this.currency_id,
				dollar_rate: this.dollar_rate,
				vat: '12',
				amount: '20000',
				regular_trx_id: '0',
				enable_tax_base: '0',
			};
			
			this.headers.push(payload);
		},
		addHeaderMounted() {
			const distData = this.dist_data;

			let unique_id = '';
			unique_id = parseInt(distData.journal_entry.document_number[0]);
			
			if(!('headers' in distData.journal_entry)){
				this.headers.push({
					company_id: 1,
					batch_number: '',
					regular_trx_type: '1',
					disbursement_type: '1',
					regular_trx_selected: '',
					document_number: unique_id,
					atc_code: '',
					currency_id: this.currency_id,
					dollar_rate: this.dollar_rate,
					vat: '12',
					amount: '20000',
					regular_trx_id: '0',
					enable_tax_base: '0',
					payee_bank: []
				});

				this.details.push({
					section_id: 1,
					regionalhead_id: 1,
					document_number: unique_id,
					document_date: format(new Date(), 'yyyy-MM-dd'),
					account_code: '',
					debit_amount: 0,
					credit_amount: 0,
					remarks: 'New Entry'
				});

				this.validations.headers.push({
					document_number: {
						is_valid: true,
						text: ''
					},
					city: {
						is_valid: true,
						text: ''
					},
					state: {
						is_valid: true,
						text: ''
					},
					zip: {
						is_valid: true,
						text: ''
					}
				});
			} else {
				this.headers = distData.journal_entry.headers;
				this.details = distData.journal_entry.details;
			}
			if(Object.keys(this.dataPO).length !== 0 ){
				this.headers.forEach(det => {
					det.payee_id = this.dataPO.payee_id;
				});
				this.details.forEach(det => {
					det.payee_id = this.dataPO.payee_id;
				});
			}
			
			distData.journal_entry['headers'] = this.headers;
			distData.journal_entry['details'] = this.details;

			//this.$store.commit('trxdisb/SET_DIST_DATA', distData);
		},
		populateRegTrxData(document_number, data) {
			let reg_trx_Data = this.regular_trx.find(o => o.id === data);
			this.getPayees();
			this.getAlphaTaxCodes();
			//this.getDepts();
			this.getBranches();
			// this.getSections();
			let sections = this.dataSections;
			let branches = this.branches;
			console.log(sections);
			// sections.forEach(det=> {
			// 	det.section_id = det.id;
			// });
			
			let get_acct_entries = JSON.parse(reg_trx_Data.acct_entries);

			let filter_existing_entries = this.populate_entries.filter(function(entry) {
				return entry.document_number !== document_number;
			});

			this.populate_entries = filter_existing_entries;

			var that = this;


			this.headers.forEach(function(header) {
				if (header.document_number === document_number) {
					header.payee_id = reg_trx_Data.payee_id;
					header.txn_type = reg_trx_Data.txn_type;
					header.atc_code = reg_trx_Data.atc_code;
					header.atc_rate = reg_trx_Data.atc_rate;
					header.currency_id = that.currency_id;
					header.dollar_rate = that.dollar_rate;
					header.vat = '12';
					header.remarks = reg_trx_Data.remarks;
					header.regular_trx_id = reg_trx_Data.id;
					header.enable_tax_base = '0';

					get_acct_entries.forEach((entry) => {
						let section = sections.filter(detail => detail.section_id == entry.section);
						let branch = branches.filter(detail => detail.id == entry.branch);
						that.populate_entries.push({
							payee_id: entry.analysis,
							department_id: entry.department,
							section_id: entry.section,
							regionalhead_id: 1,
							branch_id: entry.branch,
							document_number: document_number,
							document_date: format(new Date(), 'yyyy-MM-dd'),
							account_code: entry.account_code,
							remarks: 'New Entry',
							sections: section,
							branches: branch,
							debit_amount: entry.debit,
							credit_amount: entry.credit,
						});
					});
					header.details = that.populate_entries;
					that.details = that.populate_entries;
				}
			});

			
		},
		removeheader(key, document_number) {
			if (this.headers.length > 1) {
				this.headers.splice(key, 1);
				this.validations.headers.splice(key, 1);


				let filterDetailsByDocument = this.details.filter(detail => detail.document_number !== document_number);

				this.details = filterDetailsByDocument;
			}
		},
		filterDetailsByDocumentNumber(id) {
			return this.details.filter(detail => detail.document_number === id);
		},
		payees_on_change(value, headersKey){
			if (value) {
				let branch_id = '';
				let section_id = 0;
				let department_id = '';
				
				this.payees.filter(payee_details => {
					if(payee_details.id == value.id) {
						this.branches.filter(branches_details => {
							if(branches_details.brnc_code == payee_details.brnc_code){
								branch_id = branches_details.id;
							}
						});
						
						this.dataSections.filter(section_details => {
							if(section_details.section_code == payee_details.section_code){
								section_id = section_details.id;
							}
						});

						this.dataDepts.filter(depts_details => {
							if(depts_details.dept_code == payee_details.dept_code){
								department_id = depts_details.id;
							}
						});
						headersKey.payee_bank = payee_details.payee_banks;
						// if(payee_details.payee_banks.length !== 0 ){
						// 	headersKey.payee_account_id = payee_details.payee_banks[0].payee_account_id;
						// } else {
						// 	headersKey.payee_account_id = '';
						// }

						headersKey.atc_code = payee_details.atc_code;
						if(!payee_details.atc_code){
							headersKey.atc_rate = '';
						}

						this.dataAtc.filter(atc_details => {
							if(atc_details.atc_code === payee_details.atc_code){
								headersKey.atc_rate = atc_details.atc_rate;
							}
						});
					}
				});

				const filterDetails = this.filterDetailsByDocumentNumber(headersKey.document_number);

				filterDetails.forEach((detailData, index) => {
					if (detailData.remarks !== 'Tax Based Amount') {
						//Predefined for now. Data from API should be normalized and must return ID values.
						detailData.payee_id = value.id;
						detailData.department_id = department_id;
						detailData.section_id = section_id;
						// detailData.regionalhead_id = 1;
						detailData.branch_id = branch_id;
						// detailData.account_code = 0;
						detailData.remarks = 'New Entry';
						// detailData.debit_amount = 0;
						// detailData.credit_amount = 0;
					} else {
						//Predefined for now. Data from API should be normalized and must return ID values.
						detailData.payee_id = value.id;
						detailData.department_id = department_id;
						detailData.section_id = section_id;
						// detailData.regionalhead_id = 1;
						detailData.branch_id = branch_id;
					}

					this.getDeptData(department_id, index, headersKey.document_number);
				});
			}
		},
		atc_code_on_change(atc_code_value, key) {
			const taxCodesList = this.dataAtc;

			let filterAlphaTaxCode = taxCodesList.filter(taxCode => {
				return taxCode.atc_code === atc_code_value;
			});

			this.headers[key].atc_rate = filterAlphaTaxCode[0].atc_rate;
		},
		setDetailValue(id, key, header_document, section_id){

			const filterDetails = this.filterDetailsByDocumentNumber(header_document);
			this.payees.filter((payee_details) => {
				if(payee_details.id == id) {
					filterDetails[key].branch_id = '';
					filterDetails[key].section_id = section_id;
					filterDetails[key].department_id = '';

					this.branches.filter(branches_details => {
						if(branches_details.brnc_code == payee_details.brnc_code){
							filterDetails[key].branch_id = branches_details.id;
						}
					});

					this.dataDepts.filter(depts_details => {
						if(depts_details.dept_code == payee_details.dept_code){
							filterDetails[key].department_id = depts_details.id;
						}
					});

					this.getDeptData(filterDetails[key].department_id, key, header_document);
				}
			});
		},
		setStorage(val){
			let details = [];
			let distData = this.dist_data;

			let data = '';
			data = distData.journal_entry;
			val.details.forEach(det => {
				details.push({
					batch_number: det.batch_number,
					account_code: det.account_code,
					branch_id: det.branch_id,
					credit_amount: det.credit_amount,
					debit_amount: det.debit_amount,
					department_id: det.department_id,
					document_date: det.document_date,
					document_number: det.document_number,
					payee_id: det.payee_id,
					regionalhead_id: det.regionalhead_id,
					remarks: det.remarks,
					section_id: det.section_id,
					reference_number: det.document_number
				});
			});

			if(data){
				if(!('headers' in data)){
					data['headers'] = val.headers;
					data['details'] = details;
				}else{
					data.headers = this.headers;
					data.details = details;
				}
			}

			this.$store.commit('trxdisb/SET_DIST_DATA', distData);
		},
		setDefaultAccount(id){
			let account_code = '';
			this.dataBanks.filter(bankDetails => {
				if(bankDetails.id == id){
					account_code = bankDetails.acct_code;
				}
			});
			let account_id = '';
			if(account_code){
				this.coaData.filter(account => {
					if(account.acct_code == account_code){
						account_id = account.id;
					}
				});
			}

			if(account_id){
				const data = this.$data;

				data.headers.forEach(headerData => { 
					const filterDetails = this.filterDetailsByDocumentNumber(
						headerData.document_number
					);
					filterDetails.forEach((detailData) => {
						detailData.account_code = parseInt(account_code);
					});
				});
			}
		},
		changeData(id){
			let distData = this.dist_data;
			let data = '';
			
			if(id == 'JE'){
				if('journal_entry' in distData){
					data = distData.journal_entry;
				}
			}

			if(data) {
				this.headers = data.headers;
				this.details = data.details;
			}
		},
		setNewHeader(val){
			this.headers = val.headers;
			this.details = val.details;
		},
		async getDataSections(){
			this.dataSections = this.sections.data;
			this.nextPageSections= this.sections.next_page_url;
			let page = 2;

			while (this.nextPageSections !== null){
				await this.$store.dispatch('section/getData', page).then(response => {
					this.nextPageSections = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataSections.push(details);
					});
				});
				page++;
			}
		},
		async getDataAlphataxcodes(){
			this.dataAtc = this.alphataxcodes.data;
			this.nextPageAtc= this.alphataxcodes.next_page_url;
			let page = 2;

			while (this.nextPageAtc !== null){
				await this.$store.dispatch('alphataxcode/getData', page).then(response => {
					this.nextPageAtc = response.data.data.next_page_url;
					response.data.data.data.forEach(details => {
						this.dataAtc.push(details);
					});
				});
				page++;
			}
		},
		async getDataCoas(){
			if(this.coaData.length === 0){
				let dataCoas = this.coas.data;
				let nextPageCoas = this.coas.next_page_url;
				let page = 2;

				while (nextPageCoas !== null){
					await this.$store.dispatch('coa/getCoaData', page).then(response => {
						nextPageCoas = response.data.data.next_page_url;
						response.data.data.data.forEach(details => {
							dataCoas.push(details);
						});
					});
					page++;
				}
				let data = this.dataCoas.filter(e => parseInt(e.acct_status) === 1);
				this.$store.commit('coa/SET_COA_DATA', data);
			}
		},
	}
};
</script>

<style scoped>
  .remove-underline.v-text-field .v-input__control .v-input__slot:before {
    border-style: none;
  }
  .remove-underline.v-text-field .v-input__control .v-input__slot:after {
    border-style: none;
  }

  /*Remove spinners*/
  .input_number input[type='number'] {
    -moz-appearance: textfield;
    text-align: right;
  }

  .input_number input::-webkit-outer-spin-button,
  .input_number input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

	.document-card-container.v-card--outlined,
	.document-form-container.v-card--outlined {
		-webkit-box-shadow: none !important;
    box-shadow: none !important;
	}

	.document-card-title {
		padding: 0;
	}

	.document-form-container.v-card--outlined {
		border: 0;
	}
</style>
